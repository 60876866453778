import { navigate } from 'gatsby'
import React from 'react'

import { PageParams } from '../components/organisms/pageFrame'

const SalesformPage = (params: PageParams) => {
  const {
    pageContext: { lang },
  } = params

  if (typeof window !== 'undefined') {
    navigate(`/${lang === '' ? 'en' : lang}/request`)
  }

  return <></>
}

export default SalesformPage
